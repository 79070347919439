<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row class="mb-1 m-0">
          <b-col class="d-flex justify-content-end p-0" cols="12">
            <b-button v-if="$can('AIRCRAFT_EDIT')" class="d-flex align-items-center" :to="{ name: 'aircraft-add' }">
              <font-awesome-icon icon="plane" class="mr-50" />
              <span class="align-middle">{{ $t('action.add') }}</span>
            </b-button>
          </b-col>
        </b-row>
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="9" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $tc('pagination.entries', perPage) }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="3">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block" :placeholder="$t('action.search')" style="flex: 1" />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refAircraftListTable"
        class="position-relative"
        :items="fetchAircrafts"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('common.no_record_found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-link :to="{ name: 'aircraft-edit', params: { aircraft_id: data.item.id }, query: { action: 'update' } }" class="font-weight-bold">
            #{{ data.value }}
          </b-link>
        </template>

        <!-- Column: Aircraft -->
        <template #cell(aircraft)="data">
          <b-media vertical-align="center" :to="{ name: 'aircraft-edit', params: { aircraft_id: data.item.id }, query: { action: 'update' } }">
            <template #aside>
              <b-avatar size="32" :src="data.item.avatar" :text="avatarText(data.item.name)" :variant="`light-${resolveRoleVariant(data.item.role)}`" />
            </template>
            <b-link
              :to="{ name: 'aircraft-edit', params: { aircraft_id: data.item.id }, query: { action: 'update' } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <font-awesome-icon :icon="resolveRoleIcon(data.item.role)" class="mr-50" :class="`text-${resolveRoleVariant(data.item.role)}`" />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge pill :variant="`light-${resolveStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data" style="min-width: 15rem">
          <app-data-table-actions
            table-name="prices-management-table"
            :show-show="false"
            :show-duplicate="false"
            :show-edit="$can('ORGANIZATION_ACCOUNT_AIRCRAFT_EDIT')"
            :show-delete="false"
            @edit="$router.push({ name: 'aircraft-edit', params: { aircraft_id: data.item.id }, query: { action: 'update' } })"
          />
        </template>
      </b-table>
      <!--PAGINATION-->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">
              {{ $t('pagination.showing_entries') }} {{ dataMeta.from }} {{ $t('pagination.entries_to') }} {{ dataMeta.to }} {{ $t('pagination.entries_of') }}
              {{ dataMeta.of }} {{ $tc('pagination.entries', dataMeta.of) }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalAircrafts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import UIHelper from '@/helpers/ui'
import { fetchAircraftsRequest } from '@/request/globalApi/requests/aircraftRequests'
import AppDataTableActions from '@/components/AppDataTableActions.vue'

export default {
  name: 'Aircrafts',

  components: {
    AppDataTableActions,
  },

  mixins: [UIHelper],
  data() {
    return {
      isAddNewAircraftSidebarActive: false,
      roleOptions: [
        { label: 'General Manager', value: 'admin' },
        { label: 'Head of Business Unit', value: 'author' },
        { label: 'Finance Control Manager', value: 'editor' },
        { label: 'Head of Sales', value: 'maintainer' },
        { label: 'Sales Manager', value: 'subscriber' },
        { label: 'Customer account manager', value: 'maintainer' },
        { label: 'Business Development', value: 'subscriber' },
        { label: 'Purchasing & Supply contract manager', value: 'subscriber' },
        { label: 'Head of Operations Control', value: 'subscriber' },
        { label: 'Dispatcher', value: 'subscriber' },
        { label: 'Purchasing & Supply contract manager', value: 'subscriber' },
        { label: 'Fuel Operations', value: 'subscriber' },
      ],
      planOptions: [
        { label: 'Basic', value: 'basic' },
        { label: 'Company', value: 'company' },
        { label: 'Enterprise', value: 'enterprise' },
        { label: 'Team', value: 'team' },
      ],
      statusOptions: [
        { label: 'Pending', value: 'pending' },
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ],
      tableColumns: [
        { key: 'id', sortable: true },
        { key: 'aircraft', sortable: true },
        { key: 'registration', sortable: false },
        { key: 'homebase', sortable: true },
        { key: 'AOC', sortable: true },
        { key: 'actions', tdClass: 'actionCol' },
      ],
      perPage: 10,
      totalAircrafts: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      statusFilter: null,
    }
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.$refs.refAircraftListTable ? this.$refs.refAircraftListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalAircrafts,
      }
    },
    allFilters() {
      return `${this.currentPage}|${this.perPage}|${this.searchQuery}|${this.statusFilter}|`
    },
  },
  watch: {
    allFilters: {
      handler() {
        this.refetchData()
      },
      deep: true,
    },
  },
  methods: {
    fetchAircrafts(ctx, callback) {
      const params = {
        q: this.searchQuery,
        perPage: this.perPage,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        status: this.statusFilter,
      }
      fetchAircraftsRequest(params).then(response => {
        const { aircrafts, total } = response.data
        this.totalAircrafts = total
        callback(aircrafts)
      })
    },
    refetchData() {
      this.$refs.refAircraftListTable.refresh()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
.actionCol {
  width: 13%;
}
</style>
